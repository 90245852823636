










import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import accountStore from "@/store/modules/account";
import Account from "@/plugins/auth/account";

@Component
export default class Callback extends Vue {
  accountModule!: accountStore;
  authenticationError: string | null = null;

  created() {
    this.accountModule = getModule(accountStore, this.$store);
  }

  mounted() {
    this.$auth.completeLoginIfPossible().then((account: Account) => {
      if (account) {
        this.accountModule.update(account);
      } else {
        this.authenticationError =
          "There was a problem communicating with the Roll20 Authentication Server.";
        this.accountModule.update(undefined);
      }
      this.$router.replace("/signup");
    });
  }
}
